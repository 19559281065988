<template>
  <b-row>
    <b-col
      cols="12"
      sm="9"
      md="9"
      lg="10"
      xl="10"
    >
      <div>
        <CropImageModal
          v-if="isCropModalOpened"
          :idx="idx"
          :originalImg="originalImg"
          :originalImgUrl="originalImgUrl"
          @closeModal="closeCropModal"
          @updateImg="updateImg"
        />
      </div>

      <ProductContainer
        :product="product"
        :vendor="vendor"
        :categoryList="categoryList"
        :buyLimitCond="buyLimitCond"
        :tagbyFeeRef="tagbyFeeRef"
        :baseOptions="baseOptions"
        :shippingFreeCond="shippingFreeCond"
        :updateImg="updateImg"
        :hasStartDate="hasStartDate"
        :hasEndDate="hasEndDate"
        :formattedPrice="formattedPrice"
        :formattedSupplyPrice="formattedSupplyPrice"
        :formattedMarketPrice="formattedMarketPrice"
        :formattedShippingFee="formattedShippingFee"
        :formattedJejuShippingFee="formattedJejuShippingFee"
        :formattedMountainShippingFee="formattedMountainShippingFee"
        :formattedShippingFreeCond="formattedShippingFreeCond"
        @input:price="inputPrice"
        @input:shippingFee="inputShippingFee"
        @copyText="copyText"
        @changeVendor="changeVendor"
        @setStartDate="setStartDate"
        @deleteStartDate="deleteStartDate"
        @setEndDate="setEndDate"
        @deleteEndDate="deleteEndDate"
        @setPrice="setPrice"
        @setShippingFee="setShippingFee"
        @setTagbyFeeRate="setTagbyFeeRate"
        @toggleDutyFree="toggleDutyFree"
      />

      <ProductOptionContainer
        :isAdditional="false"
        :optionType="optionType"
        :options="baseOptions"
        :optionDepthList="baseOptionDepthList"
        :selectedOptionIndices="selectedBaseOptionIndices"
        @create:options="createBaseOptions"
        @add:optionDepth="addBaseOptionDepth"
        @delete:optionDepth="deleteBaseOptionDepth"
        @toggleAllOptions="toggleAllBaseOptions"
        @delete:options="deleteBaseOptions"
        @delete:allOptions="deleteAllBaseOptions"
        @input:depth="inputBaseOptionDepth"
        @changeOptionType="changeOptionType"
      />

      <!-- <ProductOptionContainer
        :isAdditional="true"
        :optionType="'SINGLE'"
        :options="additionalOptions"
        :optionDepthList="additionalOptionDepthList"
        :selectedOptionIndices="selectedAdditionalOptionIndices"
        @create:options="createAdditionalOptions"
        @add:optionDepth="addAdditionalOptionDepth"
        @delete:optionDepth="deleteAdditionalOptionDepth"
        @toggleAllOptions="toggleAllAdditionalOptions"
        @delete:options="deleteAdditionalOptions"
        @delete:allOptions="deleteAllAdditionalOptions"
        @input:depth="inputAdditionalOptionDepth"
      /> -->

      <ProductAbroadDetail
        :isAbroad="isAbroad"
        :abroadNation="abroadNation"
        :abroadAcceptor="abroadAcceptor"
        :abroadContact="abroadContact"
        @toggleIsAbroad="toggleIsAbroad"
        @input:nation="inputNation"
        @input:acceptor="inputAcceptor"
        @input:contact="inputContact"
      />

    </b-col>
    <b-col
      cols="12"
      sm="3"
      md="3"
      lg="2"
      xl="2"
    >
      <b-card>
        <b-button
          variant="primary"
          class="mb-1"
          block
          @click="createProduct"
        >
          상품 생성
        </b-button>
        <!-- 가상 input -->
        <input
          id="file"
          accept="image/*"
          type="file"
          style="display:none;"
          @change="uploadImg"
        >
        <b-button
          class="mb-1"
          variant="primary"
          style="cursor: default;"
          block
        >
          <label
            for="file"
            style="height: 100%; color: white; cursor:pointer;"
          >
            썸네일 넣기
          </label>
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { defineComponent, onUnmounted } from '@vue/composition-api'
import {
  BButton, BRow, BCol, BCard,
} from 'bootstrap-vue'

import axios from '@axios'
import store from '@/store'
import productStoreModule from './productStoreModule'
import ProductContainer from '@/views/commerce/components/product/ProductContainer.vue'
import ProductOptionContainer from '@/views/commerce/components/product/ProductOptionContainer.vue'
import CropImageModal from '@/views/commerce/components/product/CropImageModal.vue'
import ProductValidator from './components/product/ProductValidator'
import ProductAbroadDetail from './components/product/ProductAbroadDetail.vue'
import ProductViewModel from './ProductViewModel'
import router from '@/router'

export default defineComponent({
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    ProductContainer,
    ProductOptionContainer,
    ProductAbroadDetail,
    CropImageModal,
  },

  setup(props, context) {
    const {
      product,
      vendor,
      categoryList,
      originalImg,
      originalImgUrl,
      isCropModalOpened,
      additionalShippingFeeCond,
      shippingFreeCond,
      buyLimitCond,
      optionType,

      // base options
      baseOptions,
      baseOptionDepthList,
      selectedBaseOptionIndices,
      // additional option
      additionalOptions,
      additionalOptionDepthList,
      selectedAdditionalOptionIndices,

      isOptionRebuild,
      isDisabled,
      hasStartDate,
      hasEndDate,
      imgUrlList,

      // price
      price,
      supplyPrice,
      marketPrice,
      shippingFee,
      jejuShippingFee,
      mountainShippingFee,
      shippingFreeCondGte,

      // computed
      tagbyFeeRef,
      formattedPrice,
      formattedSupplyPrice,
      formattedMarketPrice,
      formattedShippingFee,
      formattedJejuShippingFee,
      formattedMountainShippingFee,
      formattedShippingFreeCond,

      // methods
      uploadImg,
      updateImg,
      pageRefresh,
      copyText,
      openCropModal,
      closeCropModal,
      changeVendor,
      toggleDutyFree,

      // base option
      fromFormToBaseOptions,
      getBaseOptionDepthFromBaseOptions,
      fromBaseOptionsToForm,
      inputBaseOptionDepth,
      addBaseOptionDepth,
      deleteBaseOptionDepth,
      deleteBaseOptions,
      toggleAllBaseOptions,
      deleteAllBaseOptions,
      createBaseOptions,

      // additional option
      createAdditionalOptions,
      addAdditionalOptionDepth,
      deleteAdditionalOptionDepth,
      toggleAllAdditionalOptions,
      deleteAdditionalOptions,
      deleteAllAdditionalOptions,
      inputAdditionalOptionDepth,
      changeOptionType,

      setStartDate,
      deleteStartDate,
      setEndDate,
      deleteEndDate,
      inputPrice,
      setPrice,
      setTagbyFeeRate,
      inputShippingFee,
      setShippingFee,

      convertAdditionalShippingFeeCond,
      convertShippingFreeCond,
      convertBuyLimitCond,
      convertSellingDate,
      convertTagbyFee,

      // abroad
      convertAbroadDetail,
      isAbroad,
      abroadNation,
      abroadAcceptor,
      abroadContact,
      toggleIsAbroad,
      inputNation,
      inputAcceptor,
      inputContact,
    } = ProductViewModel()

    const PRODUCT_APP_STORE_MODULE_NAME = 'app-product'
    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, productStoreModule)
    }
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME)
      }
    })

    const getCategoryList = () => store.dispatch('app-product/getCategoryList')
      .then(res => {
        categoryList.value = res.data.data
      }).catch(err => {
        console.log(err.data)
      })

    getCategoryList()

    const createProduct = () => {
      isDisabled.value.updateButton = true
      const validator = new ProductValidator(product.value)
      const validateResult = validator.validate()
      if (!validateResult) {
        isDisabled.value.updateButton = false
        return
      }
      convertAdditionalShippingFeeCond(product, additionalShippingFeeCond)
      convertShippingFreeCond(product, shippingFreeCond)
      convertBuyLimitCond(product, buyLimitCond)
      convertSellingDate(product, hasStartDate, hasEndDate)
      convertTagbyFee(product, tagbyFeeRef)
      convertAbroadDetail()
      const body = {
        idx: props.idx,
        ...product.value,
        options: {
          base_options: fromBaseOptionsToForm(baseOptions.value, optionType.value),
          additional_options: additionalOptions.value,
        },
        img_url_list: imgUrlList.value,
      }
      // console.log(body)

      axios
        .post(`${process.env.VUE_APP_BACKEND_SERVER}/manage/commerce/product/create/`, body)
        .then(() => {
          alert('생성 완료하였습니다.')
          router.push({ name: 'commerce-product-list' })
        }).catch(error => {
          const errorData = error.response.data
          alert(`생성에 실패했습니다.\n${errorData.data.message}`)
        }).finally(() => {
          isDisabled.value.updateButton = false
        })
    }

    return {
      product,
      vendor,
      categoryList,
      originalImg,
      originalImgUrl,
      isCropModalOpened,
      shippingFreeCond,
      buyLimitCond,
      optionType,

      // base option
      baseOptions,
      baseOptionDepthList,
      selectedBaseOptionIndices,
      // additional option
      additionalOptions,
      additionalOptionDepthList,
      selectedAdditionalOptionIndices,
      isOptionRebuild,
      isDisabled,
      hasStartDate,
      hasEndDate,

      // price
      price,
      supplyPrice,
      marketPrice,
      shippingFee,
      jejuShippingFee,
      mountainShippingFee,
      shippingFreeCondGte,

      // computed
      tagbyFeeRef,
      formattedPrice,
      formattedSupplyPrice,
      formattedMarketPrice,
      formattedShippingFee,
      formattedJejuShippingFee,
      formattedMountainShippingFee,
      formattedShippingFreeCond,

      // methods
      uploadImg,
      updateImg,
      pageRefresh,
      copyText,
      openCropModal,
      closeCropModal,
      changeVendor,
      toggleDutyFree,

      // base option
      fromFormToBaseOptions,
      getBaseOptionDepthFromBaseOptions,
      fromBaseOptionsToForm,
      inputBaseOptionDepth,
      addBaseOptionDepth,
      deleteBaseOptionDepth,
      deleteBaseOptions,
      toggleAllBaseOptions,
      deleteAllBaseOptions,
      createBaseOptions,

      // additional option
      createAdditionalOptions,
      addAdditionalOptionDepth,
      deleteAdditionalOptionDepth,
      toggleAllAdditionalOptions,
      deleteAdditionalOptions,
      deleteAllAdditionalOptions,
      inputAdditionalOptionDepth,
      changeOptionType,

      setStartDate,
      deleteStartDate,
      setEndDate,
      deleteEndDate,
      inputPrice,
      setPrice,
      inputShippingFee,
      setShippingFee,
      setTagbyFeeRate,
      createProduct,

      // abroad
      isAbroad,
      abroadNation,
      abroadAcceptor,
      abroadContact,
      toggleIsAbroad,
      inputNation,
      inputAcceptor,
      inputContact,
    }
  },

})
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.container {
  width: 90%;
}

.inputfile {
  display: none
}

.vendor-container .top-row {
  position: relative
}

.right-button-box {
  width: 10%;
  height: 150px;
}

.right-button-box button {
  width: 100%;
  margin-bottom: 10px;
}

</style>
